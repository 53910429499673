@font-face {
  font-family: JetBrains Mono;
  src: url("JetBrainsMono-Regular.74c02fbc.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: JetBrains Mono;
  src: url("JetBrainsMono-ExtraLight.6a54c530.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

* {
  --bg: #293241;
  --text: #e0fbfc;
  --primary: #3d5a80;
  --secondary: #98c1d9;
  --accent: #ee6c4d;
  color: var(--text);
}

html {
  background-color: var(--bg);
  font-family: JetBrains Mono, monospace;
}

::selection {
  background-color: var(--primary);
}

#container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

h1 {
  user-select: none;
  text-align: center;
}

#input-container {
  align-items: center;
  gap: 1rem;
  display: flex;
}

input[type="text"] {
  text-align: center;
  border: 2px solid var(--secondary);
  background-color: #0000;
  border-radius: .5rem;
  padding: 1rem;
  font-size: 1rem;
}

input[type="text"]:hover {
  background-color: var(--primary);
}

input[type="text"]:focus {
  background-color: var(--primary);
  outline: 2px solid var(--accent);
  border-color: #0000;
}

button {
  border: 2px solid var(--secondary);
  background-color: #0000;
  border-radius: .5rem;
  padding: 1rem;
  font-size: 1rem;
}

button:hover {
  background-color: var(--primary);
}

button:active {
  outline: 2px solid var(--accent);
  border-color: #0000;
}

input[type="checkbox"] {
  accent-color: var(--accent);
}

#uwu-input {
  align-items: center;
  gap: 1rem;
  display: flex;
}

#uwu {
  height: 1.5rem;
  width: 1.5rem;
}

label[for="uwu"] {
  font-size: 1.2rem;
}

.copypasta {
  max-width: 50vw;
}

.copypasta p {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 100;
}

.copypasta#uwupasta {
  visibility: hidden;
  position: absolute;
}

@media screen and (max-width: 900px) {
  .copypasta {
    max-width: 70vw;
  }
}

@media screen and (max-width: 600px) {
  #input-container {
    flex-direction: column;
    gap: 1rem;
    display: flex;
  }

  .copypasta {
    max-width: 90vw;
  }
}

/*# sourceMappingURL=index.8c8b841f.css.map */
