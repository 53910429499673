@font-face {
    font-family: "JetBrains Mono";
    src: url("../fonts/JetBrainsMono-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "JetBrains Mono";
    src: url("../fonts/JetBrainsMono-ExtraLight.woff2") format("woff2");
    font-weight: 100;
    font-style: normal;
}

* {
    --bg: #293241;
    --text: #E0FBFC;
    --primary: #3D5A80;
    --secondary: #98C1D9;
    --accent: #EE6C4D;

    color: var(--text);
}

html {
    font-family: "JetBrains Mono", monospace;
    
    background-color: var(--bg);
}

*::selection {
    background-color: var(--primary);
}

#container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    height: 100%;
}

h1 {
    user-select: none;
    text-align: center;
}

#input-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

input[type="text"] {
    text-align: center;
    padding: 1rem;
    border: 2px solid var(--secondary);
    border-radius: .5rem;
    background-color: transparent;
    font-size: 1rem;
}
input[type="text"]:hover {
    background-color: var(--primary);
}
input[type="text"]:focus {
    background-color: var(--primary);
    outline: 2px solid var(--accent);
    border-color: transparent
}
button {
    padding: 1rem;
    border: 2px solid var(--secondary);
    border-radius: .5rem;
    background-color: transparent;
    font-size: 1rem;
}
button:hover {
    background-color: var(--primary);
}
button:active {
    outline: 2px solid var(--accent);
    border-color: transparent
}

input[type="checkbox"] {
    accent-color: var(--accent);
}

#uwu-input {
    display: flex;
    align-items: center;
    gap: 1rem;
}
#uwu {
    height: 1.5rem;
    width: 1.5rem;
}
label[for="uwu"] {
    font-size: 1.2rem;
}

.copypasta {
    max-width: 50vw;
}
.copypasta p {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 100;
}
.copypasta#uwupasta {
    visibility: hidden;
    position: absolute;
}

@media screen and (max-width: 900px) {
    .copypasta {
        max-width: 70vw;
    }
}
@media screen and (max-width: 600px) {
    #input-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .copypasta {
        max-width: 90vw;
    }
}

